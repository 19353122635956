<script setup>
defineProps({
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  id: {
    type: [String, Number],
    default: '',
  },
})

const checked = defineModel({
  type: Boolean,
  default: false,
})
</script>

<template>
  <div class="relative cursor-pointer">
    <input
      :id="id"
      v-model="checked"
      class="peer absolute opacity-0"
      type="checkbox"
      :required="required"
    />
    <div
      class="relative flex h-6 w-6 shrink-0 items-center justify-center rounded border border-gray-400 bg-gray-100 text-secondary transition-all hover:cursor-pointer"
      :class="[
        { 'border-primary bg-primary': checked },
        { 'pointer-events-none': disabled },
      ]"
      @click="checked = !checked"
    >
      <IconChecked v-if="checked" class="h-3 w-3" />
    </div>
  </div>
</template>
